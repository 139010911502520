:root {
  --padding: 0px 10px;
  --btn-border-radius: 10px;
  --card-border-radius: 50px;
  --web_-btn_-trans: inset 0px 3px 2px rgba(255, 255, 255, 0.5), 0px 5px 15px rgba(0, 0, 0, 0.5);
  --text--white: #ffffff;
  --text--dark: #000000;
  --container-margin: 115px;
  --logo-box-shadow: 0px 10px 7px rgba(0, 0, 0, 0.4);
  --hover-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --border-radius-small: 10px;
  --border-radius-medium: 15px;
  --border-radius-large: 20px;
  --border-radius-xlarge: 30px;

  --box-shadow: 0px 8px 10px 0px rgba(0, 90, 241, 0.6), 0px 3px 1px 0px #85ccff;
  --stats-play: #fdce13;
  --Btn-G-06: #ffffff;
  --UiT-3: #3caffc;

  /*Colors */

  --ui-t-1: #3f8cf4;
  --ui-t-2: #51a1fe;
  --ui-t-3: #3caffc;
  --ui-t-4: #6cbfff;
  --ui-t-5: #29e2f0;
  --ui-t-6: #313965;
  --ui-t-7: #065fd7;
  --ui-t--v-1: #ffffff;
  --ui-t--v-2: #eaf6ff;
  --ui-t--v-3: #d7eeff;
  --hig-l--v1: #90e33f;
  --hig-l--v1-2: #69d103;
  --hig-l--v2: #fdb651;
  --hig-l--v3: #0cc3f4;
  --hig-l--v4: #ff76c5;
  --hig-l--v6: #af93ff;
  --hig-l--v5: #ffff55;
  --text--v1-1: #ffffff;
  --text--v1-2: #88b9e4;
  --text--v1-3: #6f6f6f;
  --text--v1-4: #313131;
  --text--v2: #ffff2d;
  --text--v3: #1490f9;
  --text--v4: #fdb751;
  --disable--d: #807f7f;
  --disable--l: #b3b3b3;
  --live: #00ff38;
  --lost: #ffafb9;
  --win: #cfffaa;
  --text--v5: #03933f;
  --text--v6: #bc0025;
  --special-01: #d9d9d9;

  /*Button Gradients */

  --btn-g-01: linear-gradient(180deg, #90e33f, #64cc5c);
  --btn-g-02: linear-gradient(180deg, #24d6e1, #06bffa);
  --btn-g-03: linear-gradient(180deg, #ff9dd3, #ff55c1);
  --btn-g-04: linear-gradient(180deg, #fdce13, #ffa907);
  --btn-g-05: linear-gradient(180deg, #af93ff, #9976ff);
  --btn-g-06: linear-gradient(180deg, #ffffff, #e1f0ff);
  --btn-g-disabled: linear-gradient(180deg, #b3b2b2, #999999);

  /* Effects */

  --in-shadow: 0px 3.4px 1.36px 0px rgba(255, 255, 255, 0.5), 0px 3.4px 10.21px 0px #1f5697 inset;
  --on--w: 0px 20px 30px rgba(27, 96, 188, 0.5);
  --on--b: 0px 10px 30px rgba(23, 89, 199, 1);
  --on--b--invert: 0px -10px 30px rgba(23, 89, 199, 1);
  --on--b--invert--short: 0px -10px 8px rgba(23, 89, 199, 0.4);
  --on--b--short: 0px 5px 8px rgba(23, 89, 199, 0);
  --on--w--short: 0px 5px 5px rgba(72, 138, 249, 0.5);
  --btn--s-menu: inset 0px 0px 20px rgba(23, 106, 219, 0.2);
  --web--btn--in: 0px 3px 3px 0px #eff8ff, 0px 3px 10px 0px #2464ba inset;
  --web--btn--b: 0px 3px 1px rgba(5, 162, 212, 1), 0px 8px 10px rgba(0, 90, 241, 0.6);
  --web--btn--pu: 0px 3px 1px rgba(153, 117, 255, 1), 0px 8px 10px rgba(0, 90, 241, 0.6);
  --web--btn--g: 0px 3px 1px rgba(82, 199, 61, 1), 0px 8px 10px rgba(0, 90, 241, 0.6);
  --web--btn--o: 0px 3px 1px rgba(200, 137, 11, 1), 0px 8px 10px rgba(41, 110, 227, 0.6);
  --web--btn--w: 0px 3px 1px rgba(133, 204, 255, 1), 0px 8px 10px rgba(0, 90, 241, 0.6);
  --web--btn--pi: 0px 3px 1px rgba(240, 26, 162, 1), 0px 8px 10px rgba(0, 90, 241, 0.6);
  --web--btn--red: 0px 3px 1px rgb(238, 51, 51), 0px 8px 10px rgba(241, 0, 92, 0.6);
  --in-shadow: 0px 3.4px 1.3px rgba(255, 255, 255, 0.5),
    inset 0px 3.4000000953674316px 10.210000038146973px rgba(31, 86, 151, 1);
  --web_-btn_-in: 0px 3px 1.7100000381469727px rgba(239, 248, 255, 1),
    inset 0px 3px 12.819999694824219px rgba(36, 100, 186, 1);
  --web--title-base-02: inset 0px 1.3600000143051147px 2.0399999618530273px rgba(255, 255, 255, 0.5),
    0px 3.4000000953674316px 13.609999656677246px rgba(87, 127, 189, 0.5),
    0px 2.7200000286102295px 2.7200000286102295px rgba(87, 127, 189, 1);
  --web--btn--dis: 0px 3px 1px rgba(106, 106, 106, 1), 0px 8px 10px rgba(0, 8, 16, 0.6);
  --web--btn--grey-bl: 0px 3px 1px rgba(5, 162, 212, 1), 0px 8px 10px rgba(0, 90, 241, 0.6);

  button:disabled {
    filter: grayscale(1) !important;
    opacity: 1 !important;
  }
}

* {
  /* overflow: hidden; */
}

/* -------buttons--------- */

.white-button,
.green-button,
.blue-button,
.disabled-button,
.orange-button,
.light-blue-button {
  gap: 6px;
  width: 100%;
  border-radius: var(--border-radius-large) !important;
  padding-inline: 20px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
}

button {
  overflow: hidden !important;
}

.white-button {
  background: var(--ui-t--v-2) !important;
  z-index: 2 !important;
}

.green-button {
  background: var(--btn-g-01) !important;
  box-shadow: var(--web--btn--g) !important;
  color: var(--text--v1-1) !important;
  z-index: 2 !important;
}

.blue-button {
  background: var(--btn-g-02) !important;
  box-shadow: var(--web--btn--b) !important;
  color: var(--text--v1-1) !important;
}

.orange-button {
  background: var(--btn-g-04) !important;
  box-shadow: var(--web--btn--o) !important;
}

.light-blue-button {
  background: var(--ui-t--v-3) !important;
  box-shadow: var(--web--btn--w) !important;
  color: var(--text--v1-1) !important;
}

.disabled-button {
  background: var(--btn-g-disabled) !important;
  box-shadow: var(--web--btn--dis) !important;
  color: var(--text--v1-1) !important;
}

.row-button {
  width: max-content;
  color: var(--text--v1-1) !important;
}

.sub-title-container {
  color: var(--text--white);
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  gap: 10px;
}

.sub-title-container p {
  font-size: 18px;
  text-wrap: nowrap;
  font-weight: 500;
}

.line {
  width: 100%;
  height: 2px;
  background-color: var(--text--v1-2);
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-large);
  position: relative;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--ui-t-2) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTooltip-tooltip {
  margin-top: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes shine {
  0% {
    left: -100px
  }

  20% {
    left: 100%
  }

  100% {
    left: 100%
  }
}

.shine:before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, .8),
      rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  animation: shine 2.5s infinite linear;
}

.ton-connect {
  visibility: collapse;
  max-width: auto !important;
}

/* ----------Store----------- */

.store-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.store-coming-soon {
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text--v1-1);
  font-weight: 600;
  border-radius: 20px;
}

.store-coming-soon>div {
  background-color: var(--ui-t-3);
  padding: 15px;
  padding-block: 10px;
  border-radius: var(--border-radius-medium);
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: var(--web--btn--w);
}

.store-coming-soon>div p {
  color: var(--text--white);
  font-size: 12px;
  text-align: center;
}

.store-item {
  border-radius: var(--border-radius-large);
  padding: 3px;
  padding-bottom: 8px;
  background-color: var(--ui-t--v-1);
  box-shadow: var(--web--btn--w);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.store-item-amount {
  background-color: var(--ui-t-2);
  color: var(--text--v1-4);
  border: 3px solid var(--ui-t-4);
  border-radius: var(--border-radius-large);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
}

.store-item .green-button {
  height: 30px;
  color: var(--text--v1-1) !important;
}

.connect-wallet-text {
  padding-block: 20px;
  padding-bottom: 10px;
  color: var(--text--v1-4);
}

.connect-wallet-container button {
  border-radius: 10px !important;
}

.connect-wallet-container button img {
  width: 25px;
}

.connect-wallet-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  color: var(--text--v1-1);
}

.ton-connect-button {
  display: flex;
  z-index: 222;
  justify-content: center;
  align-items: center;
}

.hide {
  visibility: hidden;
  max-width: 0px !important;
}

.ton-connect-button button {
  width: 100% !important;
  width: 180px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  background: var(--btn-g-01);
  box-shadow: var(--web--btn--g);
  margin-left: 10px;
  height: 30px !important;
}

.ton-connect-button button>div {
  color: var(--text--v1-1) !important;
  font-size: 14px;
}

.ton-connect-button button svg path {
  fill: var(--text--v1-1) !important;
}


/* ----- */

.wallet-connect-button {
  display: flex;
  z-index: 222;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  width: max-content;
  border-radius: 20px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  background: var(--btn-g-01);
  box-shadow: var(--web--btn--g);
  margin-left: 10px;
  height: 30px !important;
  padding-inline: 10px;
}

.css-r6z5ec {
  z-index: 9999999999 !important;
}

.menu-list {
  background-color: black !important;
  z-index: 9999999999 !important;
  padding-block: 0px !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.menu-list button,
.menu-list div {
  background-color: black !important;
  z-index: 9999999999 !important;
  transition: all 300ms ease-in-out !important;
  padding-block: 10px !important;
  padding-inline: 15px !important;
  gap: 5px !important;
}

.menu-list button:hover {
  background-color: gainsboro !important;
  color: black !important;
}

.tab-list {
  display: flex;
  gap: 5px;
}

.tab-list>button {
  width: 100%;
  padding-inline: 0px !important;
  border-top-right-radius: var(--border-radius-large);
  border-top-left-radius: var(--border-radius-large);
  background-color: var(--ui-t-4);
  color: var(--text--v3);
  height: 55px;
  opacity: 0.7;
  box-shadow: var(--on--b);
  z-index: 0 !important;
  font-size: 14px !important;
}

.tab-button-active {
  background-color: var(--ui-t--v-3) !important;
  color: var(--text--v1-4) !important;
  opacity: 1 !important;
}

.tab-panel>div {
  background-color: var(--ui-t--v-3);
  z-index: 10 !important;
  transform: translateY(-3px);
  box-shadow: var(--on--b);
  border-radius: 0px;
  border-bottom-left-radius: var(--border-radius-large);
  border-bottom-right-radius: var(--border-radius-large);
  padding: 15px 10px;
}

.store-balance {
  background-color: var(--ui-t--v-3);
  border-radius: var(--border-radius-medium);
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.25);
}

.store-balance>div {
  display: flex;
  gap: 5px;
  width: max-content;
  padding-inline: 15px;
  color: var(--ui-t-7);
  justify-content: center;
  align-items: center;
  height: 30px;
}

.swap-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--ui-t--v-1);
  box-shadow: var(--web--btn--w);
  border-radius: var(--border-radius-large);
  padding: 10px 15px;
}

.swap-input-container {
  position: relative;
  margin-bottom: 15px;
}

.swap-input-container img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  object-fit: contain;
}

.swap-input-box {
  border: 3px solid var(--ui-t--v-3) !important;
  border-radius: var(--border-radius-small) !important;
  text-align: center;
}

.error-text {
  position: absolute;
  bottom: -22px;
  font-size: 14px;
}

.swap-amount-structure {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-wrap: nowrap;
}

.swap-amount-structure>div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  object-fit: contain;
}

.swap-amount-structure>div img {
  object-fit: contain;
}

.swap-container+.green-button {
  color: var(--text--v1-1);
  margin-top: 20px;
}

.progress-transaction {
  position: absolute;
  height: 110%;
  width: 110%;
  left: -20px;
  top: -20px;
  display: flex;
  justify-content: center;
  z-index: 99999;
  backdrop-filter: blur(5px);
  padding-top: 140px;
}

/* ----------Store end----------- */

/* -------modals--------- */

.modal-content {
  background: var(--ui-t-2) !important;
  color: var(--text--v1-4);
  box-shadow: var(--web--btn--w) !important;
}

.modal-body {
  padding: 20px 10px !important;
  padding-bottom: 5px !important;
}

.modal-header {
  box-shadow: var(--web--btn--w);
  background-color: var(--ui-t--v-1);
  color: var(--ui-t-2);
  fill: var(--ui-t-2);
  gap: 10px;
}

.modal-footer {
  position: relative;
  padding-inline: 10px !important;
}

.modal-footer-base {
  background-color: var(--ui-t--v-1);
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: 50px;
  left: 0px;
  z-index: 1;
}

/* -------modals end--------- */

/* --------swap---------- */

.swap-confirm-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 90%;
  justify-content: center;
  margin: auto;
  text-align: center;
  color: var(--text--v1-1);
}

.swap-confirm-container span {
  color: var(--text--v1-4);
}

.swap-confirm-container>div {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.swap-confirm-container>div>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: var(--ui-t--v-1);
  color: var(--text--dark);
  width: 120px;
  height: 35px;
  padding-inline: 5px;
  border-radius: var(--border-radius-small);
}

.menu .green-button {
  padding-inline: 5px !important;
  min-width: 220px !important;
}

.swap-confirmed-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  padding-inline: 10px;
}

/* --------swap end---------- */